<script>
import Datepicker from '@/components/Datepicker'
import { Mixin } from '@/mixins/util.js'
import { Table, TableColumn, Button } from 'element-ui'
export default {
  name: 'shipping',
  mixins: [Mixin],
  components: {
    'el-table': Table,
    'el-table-column': TableColumn,
    'el-button': Button,
    Datepicker
  },
  data() {
    return {
      startDate: '2019-12-01',
      table_92: [],
      colConfigs: [
        { prop: '0', label: '販路', width: '90' },
        { prop: '1', label: '來源', width: '90' },
        { prop: '2', label: '注文ID', width: '90' },
        { prop: '3', label: '発送日', width: '100' },
        { prop: '4', label: '購入日時', width: '100' },
        { prop: '5', label: '親商品コード', width: '130' },
        { prop: '6', label: '親商品名', width: '260' },
        { prop: '7', label: '親商品個数', width: '120' },
        { prop: '8', label: '商品コード', width: '120' },
        { prop: '9', label: '商品名', width: '215' },
        { prop: '10', label: '商品個数', width: '100' },
        // { prop: '10', label: '商品金額合計', width: '130' },
        // { prop: '11', label: 'NTD', width: '110' },
        { prop: '12', label: '支払方法', width: '100' }
      ],
      pagesize: 100,
      total: 0,
      currentPage: 1
    }
  },
  computed: {
    contentData() {
      const data = this.TotableData.map(item => {
        const data = Object.entries(item)
        return data.map(t => t[1].replace(/,/g, ''))
      }).map(d=>{
        d.splice(10,2)
        return d
      })
      return data
    },
    header() {
      const title = this.colConfigs.map(item => Object.entries(item)[1][1])
      return [title]
    },
    excelData() {
      return [[...this.header, ...this.contentData]]
    },
    TotableData() {
      return this.table_92.map(items => {
        let temp = items.map((item, index) => {
          if (index < 2) return item
          if (!isNaN(item * 1)) return (item * 1).toLocaleString()
          else return item
        })
        return { ...temp }
      })
    },
    pageTotal() {
      return (this.table_92.length / this.pagesize) * 10
    }
  },
  methods: {
    dowexcel() {
      this.dwnExcel(this.excelData, '出荷', ['出荷'], [])
    },
    getChartDataWithTimeOut(y, m) {
      const result = this.fetchWithTimeout(y, m, '92')
      this.$eventBus.$emit('loadingHandler', true)
      result
        .then(res => res.text())
        .then(rep => {
          rep = JSON.parse(this.RemoveNonValidJSONChars(rep))
          this.$eventBus.$emit('loadingHandler', false)
          // console.log(rep)
          if (rep.errno !== '0') {
            this.$eventBus.$emit('logOutHandler', true)
            this.ToLoginPage()
          } else {
            const keys = Object.keys(rep).filter(item => !isNaN(item))
            keys.forEach(
              key =>
                (this[`table_${key}`] = rep[key].map(item => {
                  item[3] = this.Mosaic(
                    item[3],
                    sessionStorage.getItem('session_id')
                  )
                  item[4] = this.Mosaic(
                    item[4],
                    sessionStorage.getItem('session_id')
                  )
                  item[5] = this.Mosaic(
                    item[5],
                    sessionStorage.getItem('session_id')
                  )
                  item[7] = this.Mosaic(
                    item[7],
                    sessionStorage.getItem('session_id')
                  )
                  item[8] = this.Mosaic(
                    item[8],
                    sessionStorage.getItem('session_id')
                  )
                  return item
                }))
            )
          }
        })
        .catch(() => {
          this.$eventBus.$emit('loadingHandler', false)
          this.$eventBus.$emit('serverDownHandler', true)
          this.ToLoginPage()
        })
    },
    ToLoginPage() {
      this.timer = setTimeout(() => {
        this.$router.push({
          name: 'login',
          params: { t: '123' }
        })
      }, 2500)
    },
    current_change: function(currentPage) {
      this.currentPage = currentPage
    }
  },
  created() {
    // this.getData(new Date().getFullYear(), new Date().getMonth() + 1)
    this.getChartDataWithTimeOut(
      new Date().getFullYear(),
      new Date().getMonth() + 1
    )
  },
  mounted() {
    const menu = document.querySelector('.menu')
    const ham = document.querySelector('.ham')
    menu.style.left = '-320px'
    ham.style.display = 'block'
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  }
}
</script>

<template src="./template.html"></template>
<style lang="scss" src="./style.scss" scoped></style>
